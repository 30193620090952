@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Prevent overscroll/bounce in iOS MobileSafari and Chrome */
html,
body {
  overscroll-behavior-x: none;
}

@layer components {
  .react-select__control {
    @apply border-gray-300 bg-orange-300 dark:bg-gray-800;
  }
  .react-select__value-container {
    @apply bg-orange-300;
  }
}

textarea:focus,
input:focus {
  outline: none;
}
*:focus {
  outline: none;
}

/* REACT DATE-RANGE PICKER */

@layer components {
  .react-daterange-picker .react-daterange-picker__wrapper {
    @apply p-4 rounded-md;
  }
}
.react-daterange-picker .react-daterange-picker__wrapper {
  padding: 10px;
  border-radius: 3px;
  border-width: 2px;
  border-color: rgb(212 212 212);
}
/* REACT SELECT */

@layer components {
  /* .my-react-select-container {
  } */
  .my-react-select-container .my-react-select__control {
    @apply py-1.5 bg-white dark:bg-neutral-700 border-2 border-neutral-300 dark:border-neutral-700 hover:border-neutral-400 dark:hover:border-neutral-500;
  }

  .my-react-select-container .my-react-select__control--is-focused {
    @apply border-neutral-500 hover:border-neutral-500 dark:border-neutral-400 dark:hover:border-neutral-400 shadow-none;
  }

  .my-react-select-container .my-react-select__menu {
    @apply bg-neutral-100 dark:bg-neutral-700 border-2 border-neutral-300 dark:border-neutral-600;
  }

  .my-react-select-container .my-react-select__option {
    @apply text-neutral-600 dark:text-neutral-200 bg-neutral-100 hover:bg-neutral-200 dark:bg-neutral-700 dark:hover:bg-neutral-800;
  }
  /* .my-react-select-container .my-react-select__option--is-focused {
    @apply bg-neutral-200 dark:bg-neutral-800;
  } */

  .my-react-select-container .my-react-select__indicator-separator {
    @apply bg-neutral-400;
  }

  .my-react-select-container .my-react-select__input-container,
  .my-react-select-container .my-react-select__placeholder,
  .my-react-select-container .my-react-select__single-value {
    @apply text-neutral-600 dark:text-neutral-200;
  }
}

.react-date-picker__wrapper {
  padding: 8px;
  border-radius: 5px;
  border-color: #cccccc;
  background-color: rgb(249 250 251);
}

.country-flags ul {
  /* background-color: red !important; */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  /* border-color: rgb(28 30 77); */
  border-color: #ccc;
  border-width: 1px;
  background-color: rgb(249 250 251);
}

.country-flags ul li span {
  padding: 2px 0px;
}
.country-flags ul li {
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px;
}

.country-flags ul li svg {
  margin-right: 10px;
}
.country-flags ul input {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 5px;
}

.my-react-select__control {
  overflow: hidden;
}
.my-react-select__value-container {
  padding: 5px 8px !important;
  background-color: rgb(249 250 251) !important;
}

.my-react-select__indicators {
  background-color: rgb(249 250 251) !important;
}

.required:after {
  content: " *";
  color: red;
}

/* React Confirmation Box */
body.react-confirm-alert-body-element {
  overflow: hidden;
}

.react-confirm-alert-blur {
  filter: url(#gaussian-blur);
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(255, 255, 255, 0.9);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-body {
  font-family: Arial, Helvetica, sans-serif;
  width: 400px;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
}

.react-confirm-alert-svg {
  position: absolute;
  top: 0;
  left: 0;
}

.react-confirm-alert-body > h1 {
  margin-top: 0;
}

.react-confirm-alert-body > h3 {
  margin: 0;
  font-size: 16px;
}

.react-confirm-alert-button-group {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.react-confirm-alert-button-group > button {
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

@-webkit-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
